export default class CCSSDocument {
  id!: number
  document_type!: number
  date_presented!: Date
  document_status!: number
  comment!: string
  fileUrl!: string
  upload!: File
  ccss_id!: number
}
