import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Crm from '@/Models/Crm'
import CCSSDocument from '@/Models/CCSSDocument'
import TypeDocumentResidency from '@/Models/TypeDocumentResidency'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormDocumentCCSS extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: CCSSDocument
  @Prop({ required: false, default: null })
  isAction!: string
  selected!: any
  objCCSSDocument: CCSSDocument = new CCSSDocument()
  loading = false
  isFileEdit = false
  isDocumentEdit = false

  documentStatus = Helpers.documentStatus()

  listTypeDocumentResidency: Array<TypeDocumentResidency> = []
  get is_login(): boolean {
    return authModule.isAuthenticated
  }

  async mounted() {
    console.log('documentos ccss')
    if (this.objRow && this.isAction == 'edit') {
      this.$set(this, 'objCCSSDocument', this.objRow)
    }
  }
  async confirmation() {
    const document = this.document(this.objCCSSDocument)
    switch (this.isAction) {
      case 'add':
        await this.createCCSSDocument(document)
        break
      case 'edit':
        await this.updateCCSSDocument(document)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createCCSSDocument(objCCSSDocument: any) {
    try {
      await http.post(`/api/ccss/arcr_ccss_document/`, objCCSSDocument)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateCCSSDocument(objCCSSDocument: any) {
    try {
      await http.put(
        `/api/ccss/arcr_ccss_document/${this.objRow.id}/`,
        objCCSSDocument,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
    }
  }
  document(objDocument: CCSSDocument) {
    const formData = new FormData()
    const document = {
      document_type: objDocument.document_type,
      date_presented: Helpers.dateFormat_db(objDocument.date_presented),
      document_status: objDocument.document_status,
      comment: objDocument.comment,
      ccss_id: menuModule.id_param,
    }
    formData.append('document', JSON.stringify(document))
    if (this.isFileEdit) {
      formData.append('file', objDocument.upload)
    }
    return formData
  }
  fileChange() {
    if (this.objCCSSDocument.upload.name) {
      this.isFileEdit = true
    }
  }
  fileDocument() {
    if (this.objCCSSDocument) {
      this.isDocumentEdit = true
    }
  }
  close() {
    this.objCCSSDocument = new CCSSDocument()
    this.$emit('cleanForm')
  }
}
